.swal2-container {
    z-index: 2001 !important;
}

.swal2-popup.swal2-toast {
    font-size: 0.8em;
    padding: 5px 15px;
}

.swal2-title.sweet-alert-title {
    color: #0d1e5b;
    font-size: 18px;
    font-weight: 400;
    font-family: inherit;
    overflow-wrap: anywhere;
}

.swal2-content.sweet-alert-content {
    color: #0d1e5b;
    font-size: 16px;
    font-weight: 400;
    font-family: inherit;
}

.sweet-alert-button {
    margin-left: 5px;
    margin-right: 5px;
}

.swal2-icon.sa-icon {
    width: 80px;
    height: 80px;
    border: 4px solid;
    border-radius: 50%;
    margin: 20px auto;
    position: relative;
    box-sizing: content-box;
    font-size: 16px;
}

.sweet-alert-loader {
    width: 5em !important;
    height: 5em !important;
    padding-bottom: 5mm;
}
