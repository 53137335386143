@import 'source-sans-pro.css';
@import 'source-serif-pro.css';
@import 'montserrat.css';
@import 'swal.css';

body {
  font-family: 'montserrat', 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: #5a5a5a;
  background-color: #eceff4;
  position: relative;
}

.demo {
  padding-top: 35px;
}

.demo-div {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.demo .demo-div {
  display: block;
  font-size: 12px;
  height: 35px;
  line-height: 35px;
  background: #f16304;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

::-moz-selection {
  color: #3d901d;
  background: #d0fabf;
}

::selection {
  color: #3d901d;
  background: #d0fabf;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.reset-div {
  width: 100%;
  clear: both;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 200;
  margin: 0;
  color: #1d2e39;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 30px;
  line-height: 40px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

a.link {
  color: #74b959;
  text-decoration: none;
}

.div-shadow {
  -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-18 {
  font-size: 18px;
}

.font-24 {
  font-size: 24px;
}

.font-40 {
  font-size: 40px;
}

.dim-text {
  opacity: 0.5;
}

.text-extralight {
  font-weight: 200 !important;
}

.text-normal {
  font-weight: 400 !important;
}

.text-semibold {
  font-weight: 600 !important;
}

.text-bold {
  font-weight: 900 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-border.table td,
.no-border.table th {
  border: 0;
}

.margin-top-inverse-85 {
  margin-top: -85px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.margin-bottom-60 {
  margin-bottom: 60px !important;
}

.margin-bottom-70 {
  margin-bottom: 70px !important;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.full-width {
  width: 100% !important;
}

.width-100 {
  width: 100px !important;
}

.width-130 {
  width: 130px !important;
}

.width-50 {
  width: 50px !important;
}

.white-color {
  color: #fff !important;
}

.white-color-ni {
  color: #fff;
}

.black-color {
  color: #1d2e39;
}

.green-color {
  color: #74b959 !important;
}

.red-color {
  color: #be0600 !important;
}

.green-bg {
  background: #74b959;
}

.red-bg {
  background: #74b959;
}

.yellow-bg {
  background: #ffce1c;
}

.home {
  background: #001252 !important;
  color: #fff !important;
}

.facebook {
  background: #3a5999 !important;
  color: #fff !important;
}

.linkedin {
  background: #0a66c2 !important;
  color: #fff !important;
}

.linkedin-ni {
  background: #0a66c2;
  color: #fff;
}

.google-plus {
  background: #d84436 !important;
  color: #fff !important;
}

.twitter {
  background: #14171A !important;
  color: #fff !important;
}

.twitter-ni {
  background: #14171A;
  color: #fff;
}

.pdf {
  background: transparent !important;
  color: black;
}

[mat-flat-button]:not(:hover) {
  background-color: #9e9e9e99;
  border-color: #9e9e9e99;
}

.button-group {
  display: flex;
  gap: 8px;
}

.other-formats {
  background: #fff;
  color: #5a5a5a;
}

.eboks {
  background: url('../img/eboks-white.png') center no-repeat, #bb1d2c !important;
  background-size: 35% !important;
}

.email {
  background: #4d899b !important;
  color: #fff !important;
}

.img-responsive,
.org-img-responsive {
  width: 100%;
}

@media (max-width: 985px) {
  .org-img-responsive {
    width: auto;
    max-width: 100%;
    max-height: 50mm;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 356px) {
  .org-img-responsive {
    width: 100%;
    height: auto;
  }
}

.hidden {
  display: none !important;
}

main {
  position: relative;
}

.cdk-overlay-pane {
  overflow: auto;
}

main .white-box {
  background: #fff;
  padding: 60px;
  position: relative;
  -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
}

#grayOutDiv {
  background-color: #333;
  opacity: 0.7;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 9999;
}

.flash-message {
  z-index: 10000;
  position: relative;
}

.error-page p {
  font-size: 18px;
  margin: 0;
}

.loading-page p {
  font-size: 18px;
  margin: 0;
}

.alert.flash-message p {
  margin: 0;
  position: relative;
}

.form .success-message p {
  margin: 0;
  line-height: 35px;
}

.form .error-message p {
  margin: 0;
  line-height: 35px;
}

.alert.flash-message.alert-success p,
.alert.flash-message.alert-danger p {
  padding-left: 19px;
  text-transform: initial;
}

.alert.flash-message.alert-success p:before {
  content: "\f00c";
  font-family: FontAwesome;
  position: absolute;
  left: 0;
}

.alert.flash-message.alert-danger p:before {
  content: "\f00d";
  font-family: FontAwesome;
  position: absolute;
  left: 0;
}

.form .success-message {
  background: #e7f9e0;
  color: #3d901d;
  border-radius: 5px;
  padding: 0 10px;
}

.form .error-message {
  background: #ffdfdf;
  color: #be0600;
  border-radius: 5px;
  padding: 0 10px;
}

.form .mat-input-container {
  width: 100%;
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.form .mat-input-container .mat-form-field-placeholder {
  color: #5a5a5a;
  bottom: -25px;
}

.form .mat-input-container .mat-form-field-underline {
  background-color: #e9e9e9;
}

.form .mat-input-container .mat-form-field-infix {
  line-height: 28px;
}

.modal.addToProfile label {
  margin-bottom: 1px;
}

form .mat-dialog-content .mat-focused label,
.form .mat-input-container.mat-focused label {
  color: #74b959;
}

form .mat-dialog-content .mat-form-field-underline .mat-form-field-ripple,
.form .mat-input-container .mat-form-field-underline .mat-form-field-ripple {
  background-color: #74b959;
}

.form .mat-input-container.mat-form-field-invalid .mat-form-field-ripple {
  background-color: #f44336;
}

.form .mat-input-container.mat-form-field-invalid .mat-form-field-placeholder {
  color: #f44336;
}

.form .form-group textarea {
  border: 1px solid #e9e9e9;
  border-top: 2px solid #e9e9e9;
  font-size: 14px;
  padding: 20px;
  line-height: 25px;
  resize: none;
}

.form .form-control-div {
  display: block;
  width: 100%;
  color: #464a4c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border-radius: 0.25rem;
}

.form .input-group-addon {
  border: none;
}

.form-group {
  margin-bottom: 0.8rem;
}

.form label.control-label {
  line-height: 55px;
  font-size: 18px;
  margin-bottom: 0;
}

.form-control {
  border-radius: 0;
}

.form-control:focus {
  border-color: #c1c1c1;
}

.form .button-action {
  margin-top: 20px;
}

.form button.submit,
.mat-dialog-actions button.submit {
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  background-color: #74b959;
  padding: 0 25px;
  margin: 0;
  margin-right: 20px;
  cursor: pointer;
  line-height: 45px;
}

.mat-dialog-actions button.cancel {
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #74b959;
  font-size: 18px;
  font-weight: 600;
  background-color: #fff;
  padding: 0 25px;
  margin: 0;
  margin-right: 20px;
  cursor: pointer;
  line-height: 45px;
}

.form button.submit:disabled,
.mat-dialog-actions button.submit:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.form button.reset {
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background: none;
  border: none;
  color: #74b959;
  cursor: pointer;
  font-weight: 600;
  margin: 12px 0;
}

.form button.reset:hover {
  text-decoration: underline;
}

.form .button-action a {
  color: #5cab3e;
}

.form .checkbox-div {
  margin-top: 20px;
}

.form .checkbox-div input[type=checkbox] + label {
  width: 100%;
  clear: both;
  transition: 0.2s ease-in;
}

.modal-header .close span {
  margin-left: -5px;
  font-size: 22px;
}

.modal .modal-content .modal-footer a.link.green-color {
  cursor: pointer !important;
  font-size: 16px !important;
}

.form .checkbox-div input[type="checkbox"] + label span {
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #d0d0d0;
  border-top: 2px solid #d0d0d0;
  border-radius: 4px;
  margin-top: 5px;
  text-align: center;
  padding: 0;
  margin-bottom: 0;
  background: #fff;
}

.modal.verifiedModal .modal-dialog {
  max-width: 520px !important;
}

.modal.verifiedModal .modal-body li {
  white-space: pre-line;
}

.modal.verifiedModal .modal-body i {
  color: #74b959;
}

.modal.verifiedModal .modal-body i.fa-exclamation {
  color: #ffce1c;
}

.modal.downloadPdfModal .modal-body i {
  color: #74b959;
}

.modal.validationModal .modal-body i {
  color: #74b959;
}

.form .checkbox-div input[type="checkbox"] + label span i {
  font-size: 14px;
  color: #74b959;
  display: none;
  line-height: 17px;
}

.form .checkbox-div input[type="checkbox"] + label:hover {
  cursor: pointer;
}

.form .checkbox-div input[type="checkbox"]:checked + label span i {
  display: block;
}

.form .checkbox-div input[type=checkbox] {
  display: none;
}

/* do not group these rules */
*::-webkit-input-placeholder {
  color: #aaa !important;
}

*::-moz-placeholder {
  /* FF 19+ */
  color: #aaa !important;
}

*:-moz-placeholder {
  /* FF 4-18 */
  color: #aaa !important;
}

*:-ms-input-placeholder {
  /* IE 10+ */
  color: #aaa !important;
}

.error-page h1 {
  font-size: 70px;
}

.loading-page h1 {
  font-size: 40px;
}

.error-page a.btn {
  text-transform: uppercase;
}

.select2-container .select2-selection--single {
  width: 100%;
  max-width: 100% !important;
  height: 55px;
  line-height: 55px;
  font-size: 14px;
  padding: 0 20px;
}

.select2-container--default .select2-selection--single {
  border: 1px solid #e9e9e9;
  border-top: 2px solid #e9e9e9;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: inherit;
  font-size: 15px !important;
  line-height: 53px;
  padding: 0;
  padding-right: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 55px;
  padding: 5px 20px;
}

.select2-results__option {
  font-size: 14px;
  padding: 6px 12px;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #85c26c;
  color: #fff;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #24343e;
}

.select2-dropdown {
  border: 1px solid #e9e9e9;
}

.modal.show .modal-dialog {
  max-width: 450px;
  padding: 10px;
}

.modal.modal-large .modal-dialog {
  max-width: 992px;
}

.modal.modal-medium .modal-dialog {
  max-width: 768px;
}

.modal.modal-small .modal-dialog {
  max-width: 576px;
}

.modal-body {
  max-height: 60vh;
  overflow: hidden;
  overflow-y: scroll;
}

.modal.verifiedModal .modal-header {
  position: relative;
  padding: 0;
  margin: 30px;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.modal.downloadPdfModal.modal-header.modal-title small {
  line-height: 24px;
}

.modal.validationModal.modal-header.modal-title small {
  line-height: 24px;
}

.modal.verifiedModal .modal-header .modal-title small {
  line-height: 24px;
}

.modal.verifiedModal .modal-body {
  padding: 0;
  margin: 20px 30px;
  overflow: hidden;
}

.modal.verifiedModal .modal-header .close {
  position: absolute;
  right: -30px;
  top: -30px;
  background: #85c26c;
  color: #fff;
  opacity: 1;
  width: 20px;
  height: 20px;
  font-size: 17px;
  text-shadow: none;
  border-radius: 50%;
  text-align: center;
  line-height: 0;
  cursor: pointer;
}

.modal.downloadPdfModal .modal-header .close {
  position: absolute;
  right: -30px;
  top: -30px;
  background: #85c26c;
  color: #fff;
  opacity: 1;
  width: 20px;
  height: 20px;
  font-size: 17px;
  text-shadow: none;
  border-radius: 50%;
  text-align: center;
  line-height: 0;
  cursor: pointer;
}

.modal.validationModal .modal-header .close {
  position: absolute;
  right: -30px;
  top: -30px;
  background: #85c26c;
  color: #fff;
  opacity: 1;
  width: 20px;
  height: 20px;
  font-size: 17px;
  text-shadow: none;
  border-radius: 50%;
  text-align: center;
  line-height: 0;
  cursor: pointer;
}

.modal.verifiedModal .modal-header .close:hover {
  background: #5cab3e;
  transition: 0.2s ease-in;
}

.modal.downloadPdfModal .modal-header .close:hover {
  background: #5cab3e;
  transition: 0.2s ease-in;
}

.modal.validationModal .modal-header .close:hover {
  background: #5cab3e;
  transition: 0.2s ease-in;
}

.modal.verifiedModal .modal-header .fa.fa-check {
  color: #5cab3e;
  font-size: 55px;
}

.modal.verifiedModal .modal-header .fa.fa-exclamation {
  color: #ffce1c;
  font-size: 55px;
}

.modal.downloadPdfModal .modal-header .fa.fa-check {
  color: #5cab3e;
  font-size: 55px;
}

.modal.downloadPdfModal .modal-header .fa.fa-times {
  color: red;
  font-size: 55px;
}

.modal.validationModal .modal-header .fa.fa-check {
  color: #5cab3e;
  font-size: 55px;
}

.modal.validationModal .modal-header .fa.fa-times {
  color: red;
  font-size: 55px;
}

.modal.verifiedModal .modal-header h2.modal-title {
  float: left;
  font-weight: 700;
  color: #74b959;
  text-transform: uppercase;
  font-size: 32px;
  line-height: 28px;
}

.modal.downloadPdfModal .modal-header h2.modal-title {
  float: left;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 28px;
}

.modal.validationModal .modal-header h2.modal-title {
  float: left;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 28px;
}

.modal.verifiedModal .modal-header h2.modal-title small {
  font-size: 15px;
  color: #5a5a5a;
}

.modal.downloadPdfModal .modal-header h2.modal-title small {
  font-size: 1px;
  color: #5a5a5a;
}

.modal.validationModal .modal-header h2.modal-title small {
  font-size: 1px;
  color: #5a5a5a;
}

.modal.verifiedModal .modal-body h4 {
  margin-bottom: 20px;
}

.modal.downloadPdfModal .modal-body h4 {
  margin-bottom: 20px;
}

.modal.downloadPdfModal .modal-header {
  position: relative;
  padding: 0;
  margin: 30px;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.modal.downloadPdfModal .modal-body {
  padding: 0;
  margin: 20px 30px;
  overflow: hidden;
}

.modal.validationModal .modal-body h4 {
  margin-bottom: 20px;
}

.modal.validationModal .modal-header {
  position: relative;
  padding: 0;
  margin: 30px;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.modal.validationModal .modal-body {
  padding: 0;
  margin: 20px 30px;
  overflow: hidden;
}

.modal.addToProfile .modal-header h2.modal-title {
  font-size: 20px;
  font-weight: 400;
  color: #127cb7;
}

.modal.addToProfile .modal-body {
  font-size: 15px;
}

.modal.addToProfile .alert {
  border: 1px solid #97c1e0;
  border-left: 3px solid #4891cb;
  background: #e5f0f8;
  border-radius: 0;
  font-size: 14px;
}

.modal.addToProfile a {
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  background-color: #fff;
  color: #127cb7;
  cursor: pointer;
  text-decoration: none;
}

.modal.addToProfile a .button {
  padding: 4px 0;
  margin-left: -15px;
}

.modal.addToProfile .form-control {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 10px;
  font-size: 14px;
}

.modal.addToProfile .switch {
  top: 5px;
}

.modal.expandModal .modal-dialog {
  max-width: 1170px;
  overflow: hidden;
}

.modal.expandModal .modal-body {
  max-height: 93vh;
}

.expandModal img {
  width: 100%;
  height: auto;
}

.modal.descriptionModal .modal-dialog {
  max-width: 750px;
}

.modal.descriptionModal .modal-body {
  overflow-y: auto;
  max-height: 70vh;
  padding: 30px;
}

.processing {
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-size: 20px;
  line-height: 40px;
  margin: 0;
  padding-top: 40vh;
  overflow: hidden;
  position: fixed;
  z-index: 99999;
}

.carousel-control-next,
.carousel-control-prev,
.carousel-indicators {
  display: none;
}

/* Responsive CSS */

@media (max-width: 450px) {
  .form button.submit {
    font-size: 16px;
    border-radius: 22px;
    padding: 10px 18px;
    margin-right: 12px;
  }

  main .white-box {
    padding: 30px;
  }

  .button-group {
    display: flex;
    flex-direction: column-reverse;
    gap: 5px;
  }
  .button-group button {
    width: 100%;
  }
}

#loading-bar,
#loading-bar-spinner {
  pointer-events: none;
  -webkit-pointer-events: none;
  -webkit-transition: 350ms linear all;
  -moz-transition: 350ms linear all;
  -o-transition: 350ms linear all;
  transition: 350ms linear all;
}

#loading-bar .bar {
  -webkit-transition: width 350ms;
  -moz-transition: width 350ms;
  -o-transition: width 350ms;
  transition: width 350ms;
  background: #74b959;
  position: fixed;
  z-index: 10002;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  border-bottom-right-radius: 1px;
  border-top-right-radius: 1px;
}

#loading-bar .peg {
  position: absolute;
  width: 70px;
  right: 0;
  top: 0;
  height: 2px;
  opacity: 0.45;
  -moz-box-shadow: #74b959 1px 0 6px 1px;
  -ms-box-shadow: #74b959 1px 0 6px 1px;
  -webkit-box-shadow: #74b959 1px 0 6px 1px;
  box-shadow: #74b959 1px 0 6px 1px;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

#loading-bar-spinner {
  display: block;
  position: fixed;
  z-index: 10002;
  top: 10px;
  right: 10px;
}

#loading-bar-spinner .spinner-icon {
  width: 20px;
  height: 20px;
  border: solid 3px transparent;
  border-top-color: #74b959;
  border-left-color: #74b959;
  border-radius: 50%;
  -webkit-animation: loading-bar-spinner 400ms linear infinite;
  -moz-animation: loading-bar-spinner 400ms linear infinite;
  -ms-animation: loading-bar-spinner 400ms linear infinite;
  -o-animation: loading-bar-spinner 400ms linear infinite;
  animation: loading-bar-spinner 400ms linear infinite;
}

@-webkit-keyframes loading-bar-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes loading-bar-spinner {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes loading-bar-spinner {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes loading-bar-spinner {
  0% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.mat-ink-bar {
  background-color: #3d901d !important;
}

#flashMessages .flash-message {
  display:none;
}
#flashMessages .flash-message:last-child {
  display:block;
}
