@font-face {
    font-family: 'Montserrat';
    font-weight: 100;
    font-style: normal;
    font-stretch: normal;
    src: url('../fonts/Montserrat/Montserrat-Thin.ttf') format('truetype');
  }
  
@font-face {
    font-family: 'Montserrat';
    font-weight: 100;
    font-style: italic;
    font-stretch: normal;
    src: url('../fonts/Montserrat/Montserrat-ThinItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
    src: url('../fonts/Montserrat/Montserrat-ExtraLight.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Montserrat';
    font-weight: 200;
    font-style: italic;
    font-stretch: normal;
    src: url('../fonts/Montserrat/Montserrat-ExtraLightItalic.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Montserrat';
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    src: url('../fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Montserrat';
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    src: url('../fonts/Montserrat/Montserrat-LightItalic.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    src: url('../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    src: url('../fonts/Montserrat/Montserrat-Italic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    src: url('../fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    font-style: italic;
    font-stretch: normal;
    src: url('../fonts/Montserrat/Montserrat-MediumItalic.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    src: url('../fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    font-style: italic;
    font-stretch: normal;
    src: url('../fonts/Montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    src: url('../fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    font-style: italic;
    font-stretch: normal;
    src: url('../fonts/Montserrat/Montserrat-BoldItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    src: url('../fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Montserrat';
    font-weight: 800;
    font-style: italic;
    font-stretch: normal;
    src: url('../fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype');
  }

  
  @font-face {
    font-family: 'Montserrat';
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    src: url('../fonts/Montserrat/Montserrat-Black.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Montserrat';
    font-weight: 900;
    font-style: italic;
    font-stretch: normal;
    src: url('../fonts/Montserrat/Montserrat-BlackItalic.ttf') format('truetype');
  }
  