@font-face {
    font-family: 'Source Serif Pro';
    font-weight: 600;
    src: url('../fonts/SourceSerifPro-SemiBold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Source Serif Pro';
    font-weight: 400;
    src: url('../fonts/SourceSerifPro-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Source Serif Pro';
    font-weight: bold;
    src: url('../fonts/SourceSerifPro-Bold.ttf') format('truetype');
  }