@import '@angular/material/prebuilt-themes/indigo-pink.css';

/* slides.component.scss */

@import '~swiper/swiper.min.css';
@import '~swiper/modules/navigation/navigation.min.css';
/* @import '~swiper/modules/pagination/pagination.min.css'; */

@import '~sweetalert2/dist/sweetalert2.min.css';
@import "assets/css/style.css";
